/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import ngSanitize from 'angular-sanitize';
import 'ng-csv';

import resources from '../../../components/api/resources/resources';
import columnSelector from './column-selector-component';
import sortingHeader from '../directives/html/sorting-header-directive';
import labelRepeater from '../directives/html/label-repeater-component';
import {OBJECT_TYPE} from "../constants/object-type";
import {Downloader} from "../../../components/util/downloader";
import {statComparator} from "./comparators";
import {CHUNK_SIZE} from "../../../components/api/resources/resources-constants";

const MODULE_NAME = "query-helper";

angular.module(MODULE_NAME, [
  resources,
  'ngCsv',
  ngSanitize,
  sortingHeader,
  columnSelector,
  labelRepeater
])

  .factory('adnQueryHelper', function($filter, ChartOptionsUtil, searchResource, $translate, adnTimeShifter, statsResource, localUserProfile, LocalNetworkProfile) {

    let QueryHelper = function(ctrl, childObjectType, parentObjectType, superParentObjectType) {

      ctrl.selectAllChildren = false;
      let xlsTitle = "data.xls";

      ctrl.sel = {};
      ctrl.primaryType = childObjectType.type;
      ctrl.profileParam = childObjectType.queryColumnOptions;

      ctrl.availableSources = {
        DIRECT: true,
        SELF_SERVICE: LocalNetworkProfile.isSuiPlatform(),
        MARKETPLACE: LocalNetworkProfile.isMarketplacePlatform()
      };
      ctrl.source = '';
      ctrl.showSources = [OBJECT_TYPE.Creative, OBJECT_TYPE.LineItem, OBJECT_TYPE.Order].indexOf(childObjectType) > -1;
      ctrl.showLabels = childObjectType.type !== 'Creative';

      const preferredCurrency = localUserProfile.getPreferredCurrency();
      ctrl.availableCurrencies  = LocalNetworkProfile.get('availableCurrencies');
      ctrl.currency = ctrl.availableCurrencies.indexOf(preferredCurrency) > -1 ? preferredCurrency : LocalNetworkProfile.get('defaultCurrency');

      const groupingTypes = [];
      const subGroupingTypes = ['child', 'parent', 'superParent', 'team'];
      if (LocalNetworkProfile.isMarketplacePlatform()) {
        subGroupingTypes.push('axProduct');
      }

      let addTimeChunkGrouping = function(chunk) {
        ctrl.text = ctrl.text || {};
        ctrl.text[chunk.key] = chunk.name.toUpperCase();
        ctrl.text[chunk.key + 'Plural'] = chunk.name;
        ctrl.text[chunk.key + 'LowerCase'] = chunk.name.toLowerCase();
        ctrl.text[chunk.key + 'PluralLowerCase'] = chunk.name.toLowerCase();
        ctrl[chunk.key + 'Type'] = {
          name: chunk.name,
          apiParam: 'chunk',
          caps: chunk.key.toUpperCase(),
          type: chunk.unit,
          timeChunk: true
        };
        groupingTypes.push(chunk.key);
      };

      let addObjectTypeGrouping = function(theObjectType, prefix) {
        if (!theObjectType) {
          return;
        }
        ctrl.text = ctrl.text || {};
        ctrl.text[prefix] = $filter('adnFieldTranslate')(theObjectType.apiParam);
        ctrl.text[prefix + 'Plural'] = $filter('adnFieldTranslate')(theObjectType.apiParam + '.plural');
        ctrl.text[prefix + 'LowerCase'] = ctrl.text[prefix].toLowerCase();
        ctrl.text[prefix + 'PluralLowerCase'] = ctrl.text[prefix + 'Plural'].toLowerCase();
        ctrl[prefix + 'Type'] = Object.assign({}, theObjectType);
        ctrl[prefix + 'Type'].name = ctrl.text[prefix];
        ctrl[prefix + 'Type'].type = ctrl.text[prefix];
        ctrl[prefix + 'Search'] = function(searchElement) {
          searchResource.query({"q": searchElement.search, "types": theObjectType.type}).then(function(data) {
            ctrl[prefix + 'Results'] = data.searchResults;
          });
        };
        groupingTypes.push(prefix);
      };

      let addTargetingGrouping = function() {
        ctrl.text = ctrl.text || {};
        ctrl.text['targeting'] = 'Targeting';
        ctrl.text['targetingPlural'] = 'Targeting';
        ctrl.text['targetingLowerCase'] = 'targeting';
        ctrl.text['targetingPluralLowerCase'] = 'targeting';
        ctrl['targetingType'] = 'TARGETING';
        groupingTypes.push('targeting');
      };

      addTimeChunkGrouping(CHUNK_SIZE.MONTHLY);
      addTimeChunkGrouping(CHUNK_SIZE.WEEKLY);
      addTimeChunkGrouping(CHUNK_SIZE.DAILY);
      addTimeChunkGrouping(CHUNK_SIZE.HOURLY);
      addTimeChunkGrouping(CHUNK_SIZE.HALF_HOURLY);
      addObjectTypeGrouping(childObjectType, 'child');
      addObjectTypeGrouping(parentObjectType, 'parent');
      addObjectTypeGrouping(superParentObjectType, 'superParent');
      addObjectTypeGrouping(OBJECT_TYPE.Team, 'team');
      if (LocalNetworkProfile.isMarketplacePlatform()) {
        addObjectTypeGrouping(OBJECT_TYPE.MarketplaceProduct, 'axProduct');
      }

      const allTypes = [childObjectType, parentObjectType, superParentObjectType];

      if (allTypes.indexOf(OBJECT_TYPE.Creative) > -1) {
        addObjectTypeGrouping(OBJECT_TYPE.Advertiser, 'advertiser');
        addObjectTypeGrouping(OBJECT_TYPE.AdUnit, 'adunit');
        addObjectTypeGrouping(OBJECT_TYPE.Site, 'site');
        addObjectTypeGrouping(OBJECT_TYPE.EarningsAccount, 'publisher');
      } else if (allTypes.indexOf(OBJECT_TYPE.Order) > -1 || allTypes.indexOf(OBJECT_TYPE.Advertiser) > -1) {
        addObjectTypeGrouping(OBJECT_TYPE.AdUnit, 'adunit');
        addObjectTypeGrouping(OBJECT_TYPE.Site, 'site');
        addObjectTypeGrouping(OBJECT_TYPE.EarningsAccount, 'publisher');
      } else if (allTypes.indexOf(OBJECT_TYPE.EarningsAccount) > -1) {
        addObjectTypeGrouping(OBJECT_TYPE.Creative, 'creative');
        addObjectTypeGrouping(OBJECT_TYPE.LineItem, 'lineitem');
        addObjectTypeGrouping(OBJECT_TYPE.Order, 'order');
        addObjectTypeGrouping(OBJECT_TYPE.Advertiser, 'advertiser');
        addTargetingGrouping();
      }

      if (allTypes.indexOf(OBJECT_TYPE.Folder) > -1) {
        ctrl.hideGrouping = true;
      } else if (allTypes.indexOf(OBJECT_TYPE.UserSegment) > -1) {
        ctrl.grouping = OBJECT_TYPE.LineItem;
        ctrl.hideGrouping = true;
      } else {
        addObjectTypeGrouping(OBJECT_TYPE.Tier, 'tier');
      }

      ctrl.groupingSelections = _.map(_.filter(groupingTypes, function(check) {
        return ctrl[check + 'Type'];
      }), function(c) {
        return {val: ctrl[c + 'Type'], text: ctrl.text[c + 'Plural']};
      });
      ctrl.grouping = ctrl['childType'];

      let noneGroup = {val: '', text: 'None'};
      ctrl.subGroupingSelections = [noneGroup].concat(_.map(_.filter(subGroupingTypes, function(check) {
        return ctrl[check + 'Type'];
      }), function(c) {
        return {val: ctrl[c + 'Type'], text: ctrl.text[c + 'Plural']};
      }));
      ctrl.grouping2 = noneGroup.val;

      ctrl.transParams = '{objectType: "' + ctrl.text.childPluralLowerCase + '"}';
      ctrl.adnKey = ['LINE_ITEM', 'ADVERTISER', 'ORDER'].indexOf(childObjectType.caps) > -1 ? 'advObjectLabel' : 'pubObjectLabel';
      ctrl.advObjectLabel = [];
      ctrl.pubObjectLabel = [];
      ctrl.includeAll = false;
      ctrl.dateOption = ChartOptionsUtil.getDateOptions().last7Days;
      ctrl.changeCallback = function(dateOptionId, startDate, endDate) {
        ctrl.dateOption = ChartOptionsUtil.getDateOptions()[dateOptionId];
        ctrl.startDate = startDate;
        ctrl.endDate = endDate;
      };

      let chartOptions = angular.copy(ChartOptionsUtil.getOptions(childObjectType.caps));
      ctrl.fields = _.map(_.filter(chartOptions.queryFields, function(f) {
        return !f.availability || !LocalNetworkProfile.isPureMarketplacePlatform() || f.availability.MARKETPLACE;
      }), function(v) {
        return v;
      });
      ctrl.fields = _.filter(ctrl.fields, function(f) {
        return f.yAxis !== -1;
      });
      ctrl.fields.unshift({id: 'labels', name: 'Labels', csvName: 'Labels', apiId: 'labels'});
      ctrl.fields.unshift({id: 'name', name: 'Name', csvName: ctrl.text.child, apiId: childObjectType.apiParam});

      ctrl.changeHook = {
        fieldChange: function() {
          const hasCustom = _.find(ctrl.sel.fullFields, function(f) {
            return f.id === 'custom';
          });
          const customFields = hasCustom ? _.filter(ctrl.tableFields, function(f) {
            return f.extra;
          }) : [];
          ctrl.tableFields = _.filter(_.cloneDeep(ctrl.sel.fullFields).concat(customFields), function(f) {
            return f.id !== 'custom';
          });
        }
      };

      const regNoObjects = function(reason) {
        ctrl.reasons = reason;
        ctrl.loaded = true;
        ctrl.totals = undefined;
      };

      ctrl.sorting = {};
      ctrl.sorting.makeSortQuery = function(param) {
        if (ctrl.sorting.orderByParam === param.id) {
          ctrl.sorting.asc = !ctrl.sorting.asc;
        } else {
          ctrl.sorting.orderByParam = param.id;
          ctrl.sorting.asc = false;
        }
      };
      ctrl.sorting.sortQuery = {$resolved: true};
      ctrl.sorting.theParams = _.map(function(f) {
        return {
          param: f.id !== 'name' && f.id !== 'labels' ? f.id : undefined,
          label: f.id,
          numericColumn: f.id !== 'name' && f.id !== 'labels'
        };
      });
      ctrl.sorting.orderByParam = 'impressions';
      ctrl.sorting.asc = true;
      ctrl.sortComparator = statComparator;

      function assignDownloadFields() {
        if (ctrl.sel.allFields) {
          ctrl.downloadFields = _.map(ctrl.csvFields, function(f) {
            return f.field;
          });
          ctrl.downloadColumnOrder = _.map(ctrl.csvFields, function(f) {
            return f.columnOrder;
          });
          return;
        }
        const selFullFields = _.map(ctrl.tableFields, function(f) {
          return f.csvName || f.name;
        });
        let selFieldsOnly = _.filter(ctrl.csvFields, function(f) {
          return f.grouping;
        });
        selFieldsOnly = selFieldsOnly.concat(_.filter(ctrl.fields, function(f) {
          return (ctrl.grouping === ctrl['childType'] && f.id === 'name') || (ctrl.grouping === ctrl['childType'] && f.id === 'labels') || selFullFields.indexOf(f.csvName || f.name) > -1 || f.extra;
        }));
        selFieldsOnly = selFieldsOnly.concat(_.filter(ctrl.csvFields, function(f) {
          return f.extra;
        }));
        ctrl.downloadFields = _.map(selFieldsOnly, function(f) {
          return f.field || f.csvTranslateField || f.csvName || f.name;
        });
        ctrl.downloadColumnOrder = _.map(selFieldsOnly, function(f) {
          return f.columnOrder || f.apiId || f.id;
        });
      }

      ctrl.saveDownloadFields = function() {
        assignDownloadFields();
      };
      ctrl.saveXls = function() {
        ctrl.saveDownloadFields();
        new Downloader().saveXls2(xlsTitle, ctrl.downloadFields, ctrl.downloadColumnOrder, ctrl.getRows(), ctrl.fields);
      };

      ctrl.getRows = function() {
        let data = angular.copy(ctrl.rows);

        if (data[0].chunks) {
          _.forEach(data, function(dContainer) {
            const parentName = dContainer[ctrl.grouping.apiParam].name;
            const parentLabels = dContainer[ctrl.grouping.apiParam].labels;
            const parentSource = dContainer[ctrl.grouping.apiParam].source;
            _.forEach(dContainer.chunks, function(d) {
              d.startDate = d.startDate || _.get(d, ['lineItem', 'startDate']);
              d.endDate = d.endDate || _.get(d, ['lineItem', 'endDate']);
              _.forEach(ctrl.csvFields, function(f) {
                const isLabel = f.columnOrder.toLowerCase().indexOf('label') > -1;
                if (f.columnOrder === ctrl.withGrouping2.apiParam) {
                  const name = d[ctrl.withGrouping2.apiParam].name;
                  const labels = _.map(d[ctrl.withGrouping2.apiParam].labels, function(l) {
                    return l.text || l;
                  }) || [];
                  const source = d[ctrl.withGrouping2.apiParam].source;
                  if (source) {
                    labels.push($translate.instant("lineItem.source." + source));
                  }
                  d[ctrl.withGrouping2.apiParam] = name;
                  d[f.columnOrder] = name;
                  d.labels = _.isArray(labels) ? labels.join(", ") : "";
                } else if (f.columnOrder === ctrl.grouping.apiParam) {
                  const groupingLabels = _.map(parentLabels, function(l) {
                    return l.text || l;
                  }) || [];
                  if (parentSource) {
                    groupingLabels.push($translate.instant("lineItem.source." + parentSource));
                  }
                  d[ctrl.grouping.apiParam] = parentName;
                  d[ctrl.grouping.apiParam + 'labels'] = _.isArray(groupingLabels) ? groupingLabels.join(", ") : "";
                } else {
                  d[f.columnOrder] = isLabel ? d[f.apiId || f.columnOrder] || '' : $filter('csvOutput')(d[f.apiId || f.columnOrder], f.extra || f.columnOrder) || 0;
                }
              });
            });
          });
          data = _.flatten(_.map(data, function(d) {
            return d.chunks;
          }));
        } else {
          _.forEach(data, function(d) {
            d.startDate = d.startDate || _.get(d, ['lineItem', 'startDate']);
            d.endDate = d.endDate || _.get(d, ['lineItem', 'endDate']);
            _.forEach(ctrl.csvFields, function(f) {
              const isLabel = f.columnOrder.toLowerCase().indexOf('label') > -1;
              if (f.columnOrder === ctrl.withGrouping2.apiParam || f.columnOrder === ctrl.withGrouping.apiParam) {
                const groupingParam = f.columnOrder === ctrl.withGrouping2.apiParam ? ctrl.withGrouping2.apiParam : ctrl.withGrouping.apiParam;
                const name = d[groupingParam].name;
                const labels = _.map(d[groupingParam].labels, function(l) {
                  return l.text || l;
                }) || [];
                const source = d[groupingParam].source;
                if (source) {
                  labels.push($translate.instant("lineItem.source." + source));
                }
                d[groupingParam] = name;
                const labelsParam = f.columnOrder === ctrl.withGrouping2.apiParam ? ctrl.withGrouping2.apiParam + 'labels' : ctrl.withGrouping.apiParam + 'labels';
                const labelValue = _.isArray(labels) ? labels.join(", ") : "";
                d.labels = labelValue;
                d[labelsParam] = labelValue;
              } else {
                const value = d[f.apiId || f.columnOrder];
                d[f.columnOrder] = isLabel ? _.get(value, 'labels', value) || '' : $filter('csvOutput')(_.get(value, 'name', value), f.extra || f.columnOrder) || 0;
              }
            });
          });
        }
        return data;
      };

      ctrl.getTotals = function() {
        ctrl.searchMade = true;
        ctrl.reasons = undefined;
        ctrl.error = false;
        ctrl.totals = null;
        ctrl.rows = null;

        ctrl.startDateCall = ctrl.dateOption ? ctrl.dateOption.startDate().toDate() : moment(ctrl.startDate);
        ctrl.endDateCall = ctrl.dateOption ? ctrl.dateOption.endDate().toDate() : moment(ctrl.endDate);

        ctrl.children = ctrl.children || [];
        ctrl.parent = ctrl.parent || [];
        ctrl.superParent = ctrl.superParent || [];

        if (!ctrl.children.length && !ctrl.parent.length && !ctrl.superParent.length && !ctrl.selectAllChildren && !ctrl.advObjectLabel.length && !ctrl.pubObjectLabel.length) {
          regNoObjects("No " + ctrl.text.childPluralLowerCase + " have been found to retrieve stats for.");
          return;
        }

        ctrl.tableTitle = "Totals for Selected " + ctrl.text.childPlural + " " + $filter('date')(moment(ctrl.startDateCall).toDate(), "short") + " &ndash; " + $filter('date')(moment(ctrl.endDateCall).toDate(), "short");
        let tableTitle = "totals-" + ctrl.text.childPlural.toLowerCase() + "-" + $filter('date')(moment(ctrl.startDateCall).toDate(), "M-d-yy-H:mm") + "-" + $filter('date')(moment(ctrl.endDateCall).toDate(), "M-d-yy-H:mm");
        ctrl.csvTableTitle = tableTitle + ".csv";
        xlsTitle = tableTitle + ".xls";

        ctrl.loaded = false;
        let params = {
          groupBy: childObjectType.caps,
          startDate: ctrl.startDateCall,
          endDate: ctrl.endDateCall,
          advObjectLabel: ctrl.advObjectLabel.join(";"),
          pubObjectLabel: ctrl.pubObjectLabel.join(";"),
          includeAll: ctrl.includeAll
        };

        _.forEach(ctrl.fields, function(f) {
          f.csvTranslateField = f.key ? $filter('adnFieldTranslate')(f.key) : undefined;
        });
        ctrl.csvFields = _.map(ctrl.fields, function(f) {
          return {
            field: f.csvTranslateField || f.csvName || f.name,
            columnOrder: f.apiId || f.id
          };
        });
        if (ctrl.grouping.timeChunk) {
          // Time groupings, e.g. daily, default to sorting by the "name" which holds the timestamp
          ctrl.sorting.orderByParam = 'name';
          ctrl.sorting.asc = false;
        } else {
          ctrl.sorting.orderByParam = 'impressions';
          ctrl.sorting.asc = true;
        }
        if (ctrl.grouping !== ctrl['childType']) {
          params.groupBy = ctrl.grouping.caps;

          if (ctrl.grouping2) {
            if (childObjectType.type === 'UserSegment') {
              params.aggregateTo = null;
            } else {
              params.aggregateTo = ctrl.grouping2.caps || childObjectType.caps;
            }
            ctrl.csvFields.shift();
            ctrl.csvFields.unshift({field: ctrl.grouping2.name + " Labels", columnOrder: "labels", grouping: true});
            ctrl.csvFields.unshift({field: ctrl.grouping2.name, columnOrder: ctrl.grouping2.apiParam, grouping: true});
          }
          ctrl.csvFields.unshift({field: ctrl.grouping.name + " Labels", columnOrder: ctrl.grouping.apiParam + "labels", grouping: true});
          ctrl.csvFields.unshift({field: ctrl.grouping.name, columnOrder: ctrl.grouping.apiParam, grouping: true});
        }

        if (ctrl.selectAllChildren && !ctrl.bidCurrency) {
          params.advObjectLabel = "";
          params.pubObjectLabel = "";
          params.bidCurrency = null;
          if (childObjectType.type === 'UserSegment') {
            params.all = "SEGMENT_TARGETS";
          } else if (!params.bidCurrency) {
            params.all = childObjectType.apiParam;
          }
        } else {
          params.bidCurrency = ctrl.bidCurrency;

          if (ctrl.children.length > 0) {
            if (childObjectType.type === 'UserSegment') {
              params.segmentTargets = _.join(_.map(ctrl.children, 'id'), ';');
              params.aggregateTo = null;
            } else {
              params[childObjectType.apiParam + 'Id'] = _.join(_.map(ctrl.children, 'id'), ';');
            }
          }
          if (ctrl.parent.length > 0) {
            params[parentObjectType.apiParam + 'Id'] = _.join(_.map(ctrl.parent, 'id'), ';');
          }
          if (ctrl.superParent.length > 0) {
            params[superParentObjectType.apiParam + 'Id'] = _.join(_.map(ctrl.superParent, 'id'), ';');
          }
        }
        params.source = ctrl.source;

        let processResult = function(data) {
          _.forEach(_.get(data, ['chunks'], []), function(c) {
            if (_.get(c, 'lineItem')) {
              c.lineItem.startDate = c.lineItem.startDate ? adnTimeShifter.timeShift(c.lineItem.startDate) : null;
              c.lineItem.endDate = c.lineItem.endDate ? adnTimeShifter.timeShift(c.lineItem.endDate) : null;
            }
            if (_.get(c, 'chunkStart')) {
              const formattedChunkStart = $filter('date')(moment(c.chunkStart).toDate(), "short");
              c[ctrl.grouping.apiParam] = {name: formattedChunkStart};
            }
          });
          if (ctrl.grouping === 'TARGETING') {
            ctrl.rows = [];
            for (const [key, value] of Object.entries(data)) {
              value['targeting'] = {name: key};
              ctrl.rows.push(value);
            }
            ctrl.withGrouping = {apiParam: 'targeting'};
          } else {
            ctrl.rows = data.chunks;
            ctrl.totals = data.totals;
            ctrl.withGrouping = _.assign({}, ctrl.grouping);
            ctrl.withGrouping2 = _.assign({}, ctrl.grouping !== ctrl.childType ? ctrl.grouping2 : ctrl.childType);
          }

          ctrl.tableFields = _.cloneDeep(ctrl.sel.fullFields);
          ctrl.tableFields = _.filter(ctrl.tableFields, function(f) {
            return !f.extra && f.id !== 'custom';
          });
          ctrl.csvFields = _.filter(ctrl.csvFields, function(f) {
            return !f.extra;
          });

          const showCustom = _.find(ctrl.sel.fullFields, function(f) {
            return f.id === 'custom';
          });
          if (showCustom) {
            const extras = ChartOptionsUtil.getLineItemCustomFields(ctrl.totals);
            const averages = ChartOptionsUtil.getCustomFieldsAverage();
            _.forEach(extras, function(extra) {
              const idExtra = extra.replaceAll(/ /g, "-");
              const hasAverages = averages.indexOf(extra) > -1;
              const basicCountExtra = hasAverages ? " count" : "";
              ctrl.tableFields.push({id: idExtra, name: extra + basicCountExtra, key: idExtra, text: extra + basicCountExtra, csvName: extra, columnOrder: extra + "count", apiId: extra, extra: 'count'});
              ctrl.csvFields.push({field: extra + basicCountExtra, key: idExtra, columnOrder: extra + "count", apiId: extra, extra: "count"});
              if (hasAverages) {
                ctrl.tableFields.push({id: idExtra, name: extra + " average", key: idExtra, text: extra + " avg.", csvName: extra, columnOrder: extra + "averageValue", apiId: extra, extra: 'averageValue'});
                ctrl.csvFields.push({field: extra + " average", key: idExtra, columnOrder: extra + "averageValue", apiId: extra, extra: "averageValue"});
              }
              if (idExtra !== extra) {
                _.forEach(ctrl.rows, function(r) {
                  if (r.extra) {
                    r[idExtra] = _.cloneDeep(r.extra);
                  }
                });
              }
            });
          }

          assignDownloadFields();
          ctrl.loaded = true;

          if (!ctrl.rows || ctrl.rows.length < 1) {
            regNoObjects("No stats have been found for the " + ctrl.text.childPluralLowerCase + " requested.");
          }
        };

        let processError = function(err) {
          ctrl.objError = _.get(err, ['data', 'code']) === 'error.object.not.found';
          ctrl.error = true;
        };

        if (ctrl.currency) {
          params.currency = ctrl.currency;
        }
        if (ctrl.grouping === 'TARGETING') {
          params.aggregateTo = null;
          statsResource.getTargeting(params, processResult, processError);
        } else {
          statsResource.get(params, processResult, processError);
        }
      };
    };

    return {
      setUpQuery: function(ctrl, childObjectType, parentObjectType, superParentObjectType) {
        new QueryHelper(ctrl, childObjectType, parentObjectType, superParentObjectType);
      }
    };
  });

export default MODULE_NAME;